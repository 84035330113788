@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Google Sans';
  src:
    local('Google Sans'),
    url(./assets/fonts/GoogleSans-Thin.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src:
    local('Google Sans'),
    url(./assets/fonts/GoogleSans-Light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src:
    local('Google Sans'),
    url(./assets/fonts/GoogleSans-Regular.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src:
    local('Google Sans'),
    url(./assets/fonts/GoogleSans-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src:
    local('Google Sans'),
    url(./assets/fonts/GoogleSans-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src:
    local('Google Sans'),
    url(./assets/fonts/GoogleSans-Black.ttf) format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Arima';
  src: url('./assets/fonts/Arima-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Arima';
  src: url('./assets/fonts/Arima-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Arima';
  src: url('./assets/fonts/Arima-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Arima';
  src: url('./assets/fonts/Arima-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Arima';
  src: url('./assets/fonts/Arima-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Arima';
  src: url('./assets/fonts/Arima-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Arima';
  src: url('./assets/fonts/Arima-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

body,
.toastBody {
  font-family: 'Arima', sans-serif;
}

#canvas {
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
}

::-webkit-scrollbar {
  display: none;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  display: none;
}

html {
  background-color: #2d2b33;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

body {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100vw;
  background-color: #2d2b33;
  color: white;
  overflow-x: hidden;
  overflow-y: scroll;
  overscroll-behavior: none;
}

.header-and-bottom-nav {
  padding-top: calc(330px * 89 / 223 + 16px);
  padding-bottom: 32px;
}

#root {
  height: 100%;
  overflow: auto;
  overscroll-behavior: auto;
}

@layer base {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
